




























import { Component, Vue, Prop } from "vue-property-decorator";
import ProgressBarCircle from "@/components/ProgressBar/ProgressBarCircle.vue";
import ProgressBarLinear from "@/components/ProgressBar/ProgressBarLinear.vue";

@Component({
  components: { ProgressBarLinear, ProgressBarCircle },
})
export default class LMSDashboardLinkWithDescription extends Vue {
  public name = "LMSDashboardLinkWithDescription";

  @Prop({ default: () => "", type: String })
  public imgSrc!: any;

  @Prop({ default: () => "", type: String })
  public label!: any;

  @Prop({ default: () => "", type: String })
  public title!: any;

  @Prop({ default: () => "", type: String })
  public description!: any;

  @Prop({ default: () => "", type: String })
  public link!: any;

  @Prop({ default: () => "", type: String })
  public labelBackgroundColor!: any;

  // In Future, we can use maybe a more proper way, but this one for a temporary solution
  public handleClick(link: string): void {
    if (!link) {
      return;
    }

    if (link.startsWith("http")) {
      // Open the link in a new tab
      window.open(link, "_blank");
    } else {
      // Find the <a> element with the specified href
      const targetLink = document.querySelector(`a[href="${link}"]`);

      // Check if the element exists, then trigger a click
      if (targetLink instanceof HTMLAnchorElement) {
        targetLink.click();
      } else {
        console.log(`Link with href="${link}" not found.`);
      }
    }
  }
}
