import { render, staticRenderFns } from "./LMSDashboardCover.vue?vue&type=template&id=f7ec29cc&scoped=true&"
import script from "./LMSDashboardCover.vue?vue&type=script&lang=ts&"
export * from "./LMSDashboardCover.vue?vue&type=script&lang=ts&"
import style0 from "./LMSDashboardCover.vue?vue&type=style&index=0&id=f7ec29cc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7ec29cc",
  null
  
)

export default component.exports